import axios from '@/plugins/axios'

export default {
    async buscar (idArea, id) { 
        return await axios.get(`areas/${idArea}/tabelas/${id}`)
    },

    async inserir (idArea, dados) {
        return await axios.post(`areas/${idArea}/tabelas`, dados)
    },

    async atualizar (idArea, id, dados) {
        return await axios.put(`areas/${idArea}/tabelas/${id}`, dados)
    },

    async deletar (id) {
        return await axios.delete(`areas/${id}`)
    },
}
